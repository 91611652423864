/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { IntlProvider, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Modal from 'react-modal';

import FlagIcon from './components/FlagIcon';
import ModalOffer from './components/ModalOffer';

import './sass/main.scss';

import PhotoOne from './img/HotelGullfoss/comfort-3.jpg';
import PhotoTwo from './img/HotelGullfoss/comfort-1.jpg';
import PhotoThree from './img/HotelGullfoss/gullfoss-pic.jpg';

import GalleryItemOne from './img/HotelGullfoss/comfort-13.jpg';
import GalleryItemTwo from './img/HotelGullfoss/comfort-14.jpg';
import GalleryItemThree from './img/HotelGullfoss/comfort-5.jpg';
import GalleryItemFour from './img/HotelGullfoss/comfort-9.jpg';
import GalleryItemFive from './img/HotelGullfoss/comfort-10.jpg';
import GalleryItemSix from './img/HotelGullfoss/comfort-19.jpg';
import GalleryItemSeven from './img/HotelGullfoss/comfort-17.jpg';

import RoomsOne from './img/HotelGullfoss/rooms-1.jpg';
import RoomsTwo from './img/HotelGullfoss/rooms-2.jpg';
import RoomsEight from './img/HotelGullfoss/rooms-8.jpg';
import RoomsFourteen from './img/HotelGullfoss/rooms-14.jpg';
import RoomsFifteen from './img/HotelGullfoss/rooms-15.jpg';
import RoomsSeventeen from './img/HotelGullfoss/rooms-17.jpg';

import ExploreOne from './img/explore/explore-1.jpg';
import ExploreTwelve from './img/explore/explore-12.jpg';
import ExploreThirteen from './img/explore/explore-13.jpg';
import ExploreSeventeen from './img/explore/explore-17.jpg';
import ExploreEighteen from './img/explore/explore-18.jpg';
import ExploreNineteen from './img/explore/explore-19.jpg';
import ExploreTwenty from './img/explore/explore-20.jpg';
import ExploreTwentyOne from './img/explore/explore-21.jpg';
import ExploreTwentyTwo from './img/explore/explore-22.jpg';

import sprite from './img/sprite.svg';
import Logo from './img/logo.png';
import Iceland from './img/iceland4.png';

import Menu from './img/menu24-25.pdf';

import translations from './translations/translations.json';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '18px',
    maxWidth: '800px',
  },
  overlay: {
    zIndex: '150',
  },
};

const pathname = window.location.pathname;
Modal.setAppElement('#root');

function App() {
  const [locale, setLocale] = useState('en');
  const [displayModal, setDisplayModal] = useState();
  const [redirectLang, setRedirectLang] = useState();

  const TravelCarSVG = sprite + '#icon-travel-car';
  const FacebookSVG = sprite + '#icon-facebook';
  const TwitterSVG = sprite + '#icon-twitter';
  const TripAdvisorSVG = sprite + '#icon-tripadvisor';
  const IsSVG = sprite + '#icon-is';

  useEffect(() => {
    if (pathname === '/is') {
      setLocale('is');
      // setDisplayModal(true);
      setRedirectLang('is');
    } else {
      setLocale('en');
      // setDisplayModal(false);
      setRedirectLang('en');
    }
  }, []);

  useEffect(() => {
    if (locale === 'is') {
      // setDisplayModal(true);
      setRedirectLang('is');
    } else {
      setRedirectLang('en');
    }
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale='en'
      messages={translations[locale]}
    >
      <header className='header'>
        <div
          className='header__logo-box'
          onClick={() => scroll.scrollToTop()}
          onKeyPress={() => scroll.scrollToTop()}
          role='button'
          tabIndex='0'
        >
          <img src={Logo} alt='Logo' className='header__logo' />
        </div>
        <nav className='header__navigation'>
          <ul className='header__navigation-bar'>
            <li className='header__navigation-item'>
              <Link
                className='header__navigation-link'
                to='welcome'
                spy={false}
                smooth={true}
                duration={500}
                offset={-240}
                tabIndex='0'
              >
                <FormattedMessage id='welcome' />
              </Link>
            </li>
            <li className='header__navigation-item'>
              <Link
                className='header__navigation-link'
                to='comfort'
                spy={false}
                smooth={true}
                duration={500}
                offset={-200}
                tabIndex='0'
              >
                <FormattedMessage id='comfort' />
              </Link>
            </li>
            <li className='header__navigation-item'>
              <Link
                className='header__navigation-link'
                to='rooms'
                spy={false}
                smooth={true}
                duration={500}
                offset={-80}
                tabIndex='0'
              >
                <FormattedMessage id='rooms' />
              </Link>
            </li>
            <li className='header__navigation-item'>
              <Link
                className='header__navigation-link'
                to='explore'
                spy={false}
                smooth={true}
                duration={500}
                offset={-120}
                tabIndex='0'
              >
                <FormattedMessage id='explore' />
              </Link>
            </li>
            <li className='header__navigation-item'>
              <Link
                className='header__navigation-link'
                to='location'
                spy={false}
                smooth={true}
                duration={500}
                offset={-140}
                tabIndex='0'
              >
                <FormattedMessage id='location' />
              </Link>
            </li>
            {/* {locale === 'is' && (
              <li className='header__navigation-item'>
                <button
                  className='header__navigation-button header__navigation-link'
                  onClick={() => setDisplayModal(true)}
                  style={{ border: 'none', background: 'transparent' }}
                >
                  Tilboð
                </button>
              </li>
            )} */}
          </ul>
        </nav>
        <div className='header__right'>
          <div className='header__flags'>
            <button
              className={classnames('header__flag', {
                'header__flag-selected': locale === 'en',
              })}
              onClick={() => setLocale('en')}
              // onKeyDown={() => setLocale('en')}
              tabIndex='0'
              disabled={locale === 'en'}
            >
              <FlagIcon code='gb' size='3x' className='header__flag-icon' />
            </button>
            <button
              className={classnames('header__flag', {
                'header__flag-selected': locale === 'is',
              })}
              onClick={() => setLocale('is')}
              // onKeyDown={() => setLocale('is')}
              tabIndex='0'
              disabled={locale === 'is'}
            >
              <FlagIcon code='is' size='3x' className='header__flag-icon' />
            </button>
          </div>
          <a
            href={`https://property.godo.is/booking2.php?propid=26541&invoicee=16548&lang=${locale}`}
            className='header__btn header__btn--blue header__btn--animated'
          >
            <FormattedMessage id='bookNow' />
          </a>
        </div>
      </header>
      <main>
        <section className='section-hero'>
          <div className='header__text-box'>
            <h1 className='heading-primary'>
              <span className='heading-primary--main'>
                {' '}
                <FormattedMessage id='headingPrimary' />
              </span>
              <span className='heading-primary--sub'>
                <FormattedMessage id='headingSecondary' />
              </span>
            </h1>
          </div>
        </section>

        <section className='section-welcome'>
          <article className='article-welcome' name='welcome'>
            <div className='u-center-text'>
              <h2 className='heading-secondary u-border-bottom-blue'>
                <FormattedMessage id='welcome' />
              </h2>
            </div>

            <div className='row'>
              <div className='col-1-of-2'>
                <h3 className='heading-tertiary u-margin-top-small u-margin-bottom-small'>
                  <FormattedMessage id='welcomeFirstTitle' />
                </h3>
                <p className='paragraph'>
                  <FormattedMessage id='welcomeFirstText' />
                </p>
                <h3 className='heading-tertiary u-margin-bottom-small'>
                  <FormattedMessage id='welcomeSecondTitle' />
                </h3>
                <p className='paragraph'>
                  <FormattedMessage id='welcomeSecondText' />
                </p>
              </div>
              <div className='col-1-of-2'>
                <div className='composition'>
                  <img
                    src={PhotoOne}
                    alt='Seating'
                    className='composition__photo composition__photo--p1'
                  />
                  <img
                    src={PhotoTwo}
                    alt='House'
                    className='composition__photo composition__photo--p2'
                  />
                  <img
                    src={PhotoThree}
                    alt='Reception'
                    className='composition__photo composition__photo--p3'
                  />
                </div>
              </div>
            </div>
          </article>
          <article className='article-comfort' name='comfort'>
            <Element className='u-center-text'>
              <h2 className='heading-secondary u-border-bottom-blue'>
                <FormattedMessage id='comfort' />
              </h2>
            </Element>
            <div className='section-explore__info u-margin-bottom-medium u-center-text'>
              <p>
                <FormattedMessage id='comfortText' />
              </p>
            </div>
            <div className='gallery'>
              {/* <figure className='gallery__item gallery__item--1'>
                <img
                  src={GalleryItemOne}
                  alt='Gallery 1'
                  className='gallery__img'
                />
              </figure> */}
              <figure className='gallery__item gallery__item--2'>
                <img
                  src={GalleryItemTwo}
                  alt='Gallery 2'
                  className='gallery__img'
                />
              </figure>
              <figure className='gallery__item gallery__item--4'>
                <img
                  src={GalleryItemThree}
                  alt='Gallery 4'
                  className='gallery__img'
                />
              </figure>
              <figure className='gallery__item gallery__item--5'>
                <img
                  src={GalleryItemFour}
                  alt='Gallery 5'
                  className='gallery__img'
                />
              </figure>
              <figure className='gallery__item gallery__item--6'>
                <img
                  src={GalleryItemFive}
                  alt='Gallery 6'
                  className='gallery__img'
                />
              </figure>
              <figure className='gallery__item gallery__item--7'>
                <img
                  src={GalleryItemSix}
                  alt='Gallery 7'
                  className='gallery__img'
                />
              </figure>
              <figure className='gallery__item gallery__item--8'>
                <img
                  src={GalleryItemOne}
                  alt='Gallery 1'
                  className='gallery__img'
                />
              </figure>
              {/* <figure className='gallery__item gallery__item--8'>
                <img
                  src={GalleryItemSeven}
                  alt='Gallery 8'
                  className='gallery__img'
                />
              </figure> */}
            </div>
          </article>

          <article className='article-comfort' name='comfort'>
            <Element className='u-center-text'>
              <h2 className='heading-secondary u-border-bottom-blue '>
                <FormattedMessage id='menu' />
              </h2>
            </Element>

            <div className='section-menu'>
              <div>
                <p className='paragraph'>
                  <FormattedMessage id='menuTextFirst' />
                </p>
                <p className='paragraph'>
                  <FormattedMessage
                    id='menuTextSecond'
                    values={{
                      b: (chunks) => (
                        <strong style={{ fontWeight: 'bold' }}>{chunks}</strong>
                      ),
                    }}
                  />
                </p>
                {/* <p>
                  <FormattedMessage id='menuTextThird' />
                </p>
                <p>
                  <FormattedMessage id='menuTextFourth' />
                </p> */}

                <a
                  href={Menu}
                  target='_blank'
                  rel='noreferrer'
                  className='section-menu__link'
                >
                  <FormattedMessage id='seeMenu' />
                </a>
              </div>
              <div className='menu-gallery'>
                <figure className='menu_gallery__item menu_gallery__item--1'>
                  <img
                    src={GalleryItemSeven}
                    alt='Menu Gallery 1'
                    className='menu-gallery__img'
                  />
                </figure>
              </div>
            </div>
          </article>
        </section>

        <section
          className='section-rooms u-padding-top u-padding-sides-small'
          name='rooms'
        >
          <div className='u-center-text u-padding-top'>
            <h2 className='heading-secondary u-border-bottom-blue'>
              {' '}
              <FormattedMessage id='rooms' />
            </h2>
          </div>

          <div className='section-explore__info u-margin-bottom-big u-center-text'>
            <p className='paragraph'>
              <FormattedMessage id='roomsText' />
            </p>
          </div>

          <div className='rooms'>
            <div className='rooms__room--1'>
              <div className='rooms__content u-left-text'>
                <h3 className='heading-tertiary u-margin-bottom-small'>
                  Standard
                </h3>
                <p className='rooms__text'>
                  <FormattedMessage id='roomsStandard' />
                </p>
              </div>
              <div className='rooms-gallery'>
                <figure className='rooms-gallery__item rooms-gallery__item--1'>
                  <img
                    src={RoomsOne}
                    alt='Room Gallery 1'
                    className='rooms-gallery__img'
                  />
                </figure>
                <figure className='rooms-gallery__item rooms-gallery__item--2'>
                  <img
                    src={RoomsTwo}
                    alt='Room Gallery 2'
                    className='rooms-gallery__img'
                  />
                </figure>
                <figure className='rooms-gallery__item rooms-gallery__item--3'>
                  <img
                    src={RoomsFifteen}
                    alt='Room Gallery 3'
                    className='rooms-gallery__img'
                  />
                </figure>
              </div>
            </div>

            <div className='rooms__room--2'>
              <div className='rooms__content u-left-text'>
                <h3 className='heading-tertiary u-margin-bottom-small'>
                  Superior
                </h3>
                <p className='rooms__text'>
                  <FormattedMessage id='roomsSuperior' />
                </p>
              </div>
              <div className='rooms-gallery'>
                <figure className='rooms-gallery__item rooms-gallery__item--1'>
                  <img
                    src={RoomsEight}
                    alt='Room Gallery 1'
                    className='rooms-gallery__img'
                  />
                </figure>
                <figure className='rooms-gallery__item rooms-gallery__item--2'>
                  <img
                    src={RoomsFourteen}
                    alt='Room Gallery 2'
                    className='rooms-gallery__img'
                  />
                </figure>
                <figure className='rooms-gallery__item rooms-gallery__item--3'>
                  <img
                    src={RoomsSeventeen}
                    alt='Room Gallery 3'
                    className='rooms-gallery__img'
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className='section-explore' name='explore'>
          <div className='u-center-text u-padding-top'>
            <h2 className='heading-secondary u-border-bottom-blue'>
              <FormattedMessage id='explore' />
            </h2>
          </div>

          <div className='section-explore__info u-margin-bottom-big u-center-text'>
            <p className='paragraph'>
              <FormattedMessage id='exploreText' />
            </p>
          </div>
          <div className='explore-cards'>
            <div className='card'>
              <img
                src={ExploreTwenty}
                alt='Explore Gullfoss foto'
                className='card__picture card__picture--3'
              />
              <h4 className='card__heading'>Gullfoss</h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='gullfossText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    5 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreNineteen}
                alt='Explore Geysir'
                className='card__picture card__picture--4'
              />
              <h4 className='card__heading'>Geysir</h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='geysirText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    10 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreTwelve}
                alt='Explore north lights foto'
                className='card__picture card__picture--2'
              />
              <h4 className='card__heading'>
                <FormattedMessage id='northernLights' />
              </h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='northernLightsText' />
                </div>
                <div className='card__information'></div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreOne}
                alt='Explore riding'
                className='card__picture card__picture--1'
              />
              <h4 className='card__heading'>
                <FormattedMessage id='riding' />
              </h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='ridingText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    10 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreThirteen}
                alt='Explore forrest'
                className='card__picture card__picture--5'
              />
              <h4 className='card__heading'>
                <FormattedMessage id='hiking' />
              </h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='hikingText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    3 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreTwentyTwo}
                alt='Explore glacier'
                className='card__picture card__picture--6'
              />
              <h4 className='card__heading'>
                <FormattedMessage id='glacierTours' />
              </h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='glacierToursText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    5 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>

            <div className='card'>
              <img
                src={ExploreEighteen}
                alt='Explore nature bath'
                className='card__picture card__picture--7'
              />
              <h4 className='card__heading'>
                <FormattedMessage id='natureBath' />
              </h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='natureBathText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    30 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreTwentyOne}
                alt='Explore Þingvellir'
                className='card__picture card__picture--9'
              />
              <h4 className='card__heading'>Þingvellir</h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='thingvellirText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    60 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
            <div className='card'>
              <img
                src={ExploreSeventeen}
                alt='Explore the south'
                className='card__picture card__picture--8'
              />
              <h4 className='card__heading'>
                <FormattedMessage id='theSouth' />
              </h4>
              <div className='card__details'>
                <div className='card__text'>
                  <FormattedMessage id='theSouthText' />
                </div>
                <div className='card__information'>
                  <svg className='card__icon'>
                    <use href={TravelCarSVG} />
                  </svg>
                  <span className='card__information-text'>
                    30 <FormattedMessage id='minuteDrive' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section-location' name='location'>
          <div className='u-center-text u-padding-top'>
            <h2 className='heading-secondary u-border-bottom-blue'>
              <FormattedMessage id='location' />
            </h2>
          </div>
          <div className='section-location__info u-center-text'>
            <p className='paragraph'>
              <FormattedMessage id='locationText' />
            </p>
          </div>
          <div className='section-location__content'>
            <a href='https://g.page/Gullfosshotel?share'>
              <img
                src={Iceland}
                alt='Iceland map'
                className='section-location__image'
              />
              <svg className='section-location__icon'>
                <use href={sprite + '#icon-pin'} />
              </svg>
            </a>
          </div>
        </section>
      </main>
      <footer className='footer'>
        <div className='footer-content'>
          <div className='footer-content__info'>
            <a href='https://g.page/Gullfosshotel?share'>
              <svg className='footer-content__icon footer-content__icon-location'>
                <use href={sprite + '#icon-location-pin'} />
              </svg>
              <div className='footer-content__text-primary'>
                {' '}
                <FormattedMessage id='location' />
              </div>
              <div className='footer-content__text-secondary'>
                801 Selfoss Bláskógabyggð
              </div>
            </a>
          </div>

          <div className='footer-content__info'>
            <a href='tel:+3544868979'>
              <svg className='footer-content__icon footer-content__icon-phone'>
                <use href={sprite + '#icon-phone'} />
              </svg>
              <div className='footer-content__text-primary'>
                {' '}
                <FormattedMessage id='phone' />
              </div>
              <div className='footer-content__text-secondary'>
                +354 486-8979
              </div>
            </a>
          </div>

          <div className='footer-content__info'>
            <a href='mailto:info@hotelgullfoss.is'>
              <svg className='footer-content__icon footer-content__icon-mail'>
                <use href={sprite + '#icon-mail'} />
              </svg>
              <div className='footer-content__text-primary'>
                {' '}
                <FormattedMessage id='email' />
              </div>
              <div className='footer-content__text-secondary'>
                info@hotelgullfoss.is
              </div>
            </a>
          </div>
        </div>

        <div className='footer-pr'>
          <a
            href='https://www.facebook.com/www.hotelgullfoss.is'
            className='footer-pr__facebook-button'
          >
            <svg className='footer-content__icon footer-content__icon-facebook'>
              <use href={FacebookSVG} />
            </svg>
          </a>
          <a
            href='https://twitter.com/hotelgullfoss'
            className='footer-pr__twitter-button'
          >
            <svg className='footer-content__icon footer-content__icon-twitter'>
              <use href={TwitterSVG} />
            </svg>
          </a>
          <a
            href='https://www.tripadvisor.com/Hotel_Review-g315852-d1534571-Reviews-Hotel_Gullfoss-Selfoss_South_Region.html'
            className='footer-pr__tripadvisor-button'
          >
            <svg className='footer-content__icon footer-content__icon-tripadvisor'>
              <use href={TripAdvisorSVG} />
            </svg>
          </a>
        </div>
      </footer>

      {/* <ScrollLock isActive={displayModal} /> */}

      <Modal
        isOpen={displayModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setDisplayModal(false)}
        style={customStyles}
      >
        <ModalOffer
          redirectLang={redirectLang}
          setDisplayModal={setDisplayModal}
        />
      </Modal>
    </IntlProvider>
  );
}

export default App;

// <div className='modal'>
//  <button className='modal__close' onClick={close}>
//   &times;
// </button> *
// <h2 className='modal__header'>Sparidagstilboð</h2>
// <div className='modal__content'>
//   <div
//     style={{
//       float: 'right',
//       position: 'inline-block',
//     }}
//   >
//     <img
//       src={RoomsThree}
//       alt='Room offer'
//       height='200px'
//       className='modal__content-image'
//     />
//   </div>
//   <div style={{ marginBottom: '1rem' }}>
//     Sérstök Sparidagstilboð gilda í mars, apríl og maí 2021 á
//     gistingu í eina nótt eða tvær nætur.
//   </div>
//   <div>
//     <h3>Gisting í einstaklingsherbergi</h3>
//     <div style={{ marginBottom: '1.2rem' }}>
//       Gisting fyrir einn gest í einstaklings herbergi í eina nótt
//       með morgunverðarhlaðborði og 3ja rétta kvöldverði. Tilboðsverð{' '}
//       <strong>kr. 22.500 </strong>.
//       <br /> Gisting í tvær nætur með morgunverðarhlaðborði og 3ja
//       rétta kvöldverðum bæði kvöldin. Tilboðsverð{' '}
//       <strong>kr. 30.800 </strong>
//     </div>
//   </div>
//   <div>
//     <h3>Gisting fyrir 2 gesti í eina nótt</h3>
//     <div style={{ marginBottom: '1.2rem' }}>
//       Gisting fyrir 2 gesti í 2ja manna herbergi í eina nótt með
//       morgunverðarhlaðborði og 3ja rétta kvöldverði. Tilboðsverð{' '}
//       <strong>kr. 28.900 </strong>
//     </div>
//   </div>
//   <div>
//     <h3>Gisting fyrir 2 gesti í tvær nætur</h3>
//     <div style={{ marginBottom: '1.2rem' }}>
//       Gisting fyrir 2 gesti í 2ja manna herbergi í tvær nætur með
//       morgunverðarhlaðborði báða dagana og 3ja rétta kvöldverði
//       annað kvöldið. Tilboðsverð <strong>kr. 36.800 </strong>
//     </div>
//   </div>
//   <hr style={{ margin: '2rem 0' }} />
//   <div style={{ fontSize: '2rem' }}>
//     <strong>
//       Sparidagstilboðin er einungis hægt að panta í síma
//       gestamóttökunnar{' '}
//       <a href='tel:+3544868979' style={{ color: '#ffffff' }}>
//         486-8979
//       </a>{' '}
//       eða með því að senda fyrirspurn í{' '}
//       <a
//         href='mailto:info@hotelgullfoss.is'
//         style={{ color: '#ffffff' }}
//       >
//         tölvupósti
//       </a>{' '}
//       (info@hotelgullfoss.is)
//     </strong>
//   </div>
// </div>
// </div>

// <Modal
//           isOpen={displayModal}
//           // onAfterOpen={afterOpenModal}
//           onRequestClose={() => setDisplayModal(false)}
//           style={customStyles}
//           contentLabel='Example Modal'
//         >
//           <div className='modal'>
//             {/* <button className='modal__close' onClick={close}>
//               &times;
//             </button>{' '} */}
//             <h2 className='modal__header'>Sparidagstilboð</h2>
//             <div className='modal__content'>
//               <div
//                 style={{
//                   float: 'right',
//                   position: 'inline-block',
//                 }}
//               >
//                 <img
//                   src={RoomsThree}
//                   alt='Room offer'
//                   height='200px'
//                   className='modal__content-image'
//                 />
//               </div>
//               <div style={{ marginBottom: '1rem' }}>
//                 Sérstök Sparidagstilboð gilda í mars, apríl og maí 2021 á
//                 gistingu í eina nótt eða tvær nætur.
//               </div>
//               <div>
//                 <h3>Gisting í einstaklingsherbergi</h3>
//                 <div style={{ marginBottom: '1.2rem' }}>
//                   Gisting fyrir einn gest í einstaklings herbergi í eina nótt
//                   með morgunverðarhlaðborði og 3ja rétta kvöldverði. Tilboðsverð{' '}
//                   <strong>kr. 22.500 </strong>.
//                   <br /> Gisting í tvær nætur með morgunverðarhlaðborði og 3ja
//                   rétta kvöldverðum bæði kvöldin. Tilboðsverð{' '}
//                   <strong>kr. 30.800 </strong>
//                 </div>
//               </div>
//               <div>
//                 <h3>Gisting fyrir 2 gesti í eina nótt eða tvær nætur</h3>
//                 <div style={{ marginBottom: '1.2rem' }}>
//                   Gisting fyrir 2 gesti í 2ja manna herbergi í eina nótt með
//                   morgunverðarhlaðborði og 3ja rétta kvöldverði. Tilboðsverð{' '}
//                   <strong>kr. 28.900 </strong>
//                 </div>
//               </div>
//               {/* <div>
//                 <h3>Gisting fyrir 2 gesti í tvær nætur</h3>
//                 <div style={{ marginBottom: '1.2rem' }}>
//                   Gisting fyrir 2 gesti í 2ja manna herbergi í tvær nætur með
//                   morgunverðarhlaðborði báða dagana og 3ja rétta kvöldverði
//                   annað kvöldið. Tilboðsverð <strong>kr. 36.800 </strong>
//                 </div>
//               </div> */}
//               <hr style={{ margin: '2rem 0' }} />
//               <div style={{ fontSize: '2rem' }}>
//                 <strong>
//                   Sparidagstilboðin er einungis hægt að panta í síma
//                   gestamóttökunnar{' '}
//                   <a href='tel:+3544868979' style={{ color: '#ffffff' }}>
//                     486-8979
//                   </a>{' '}
//                   eða með því að senda fyrirspurn í{' '}
//                   <a
//                     href='mailto:info@hotelgullfoss.is'
//                     style={{ color: '#ffffff' }}
//                   >
//                     tölvupósti
//                   </a>{' '}
//                   (info@hotelgullfoss.is)
//                 </strong>
//               </div>
//               <svg
//                 className='modal__cancel-icon'
//                 onClick={() => setDisplayModal(false)}
//               >
//                 <use href={CancelCricle} className='modal__cancel-icon' />
//               </svg>
//             </div>
//           </div>
//         </Modal>

{
  /* <Popup
open={displayModal}
modal
closeOnDocumentClick
onClose={() => setDisplayModal(false)}
>
{(close) => (
  <div className='modal'>
    <button className='modal__close' onClick={close}>
      &times;
    </button>
    <h2 className='modal__header'>Sparidagstilboð</h2>
    <div className='modal__content'>
      <div
        style={{
          float: 'right',
          position: 'inline-block',
        }}
      >
        <img
          src={RoomsThree}
          alt='Room offer'
          height='200px'
          className='modal__content-image'
        />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        Sérstök Sparidagstilboð gilda í mars, apríl og maí 2021 á
        gistingu í eina nótt eða tvær nætur. Sérstök Sparidagstilboð
        gilda í mars, apríl og maí 2021 á gistingu í eina nótt eða
        tvær nætur. Sérstök Sparidagstilboð gilda í mars, apríl og maí
        2021 á gistingu í eina nótt eða tvær nætur. Sérstök
        Sparidagstilboð gilda í mars, apríl og maí 2021 á gistingu í
        eina nótt eða tvær nætur. Sérstök Sparidagstilboð gilda í
        mars, apríl og maí 2021 á gistingu í eina nótt eða tvær nætur.
      </div>
      <div>
        <h3>Gisting í einstaklingsherbergi í eina eða tvær nætur</h3>
        <div style={{ marginBottom: '1.2rem' }}>
          Gisting fyrir einn gest í einstaklings herbergi með
          morgunverðarhlaðborði og 3ja rétta kvöldverði. Tilboðsverð
          fyrir eina nótt <strong>kr. 22.500 </strong> og{' '}
          <strong>kr. 30.800</strong> fyrir tvær nætur.
          {/* <br /> Gisting í tvær nætur með morgunverðarhlaðborði og 3ja
          rétta kvöldverðum bæði kvöldin. Tilboðsverð{' '}
          <strong>kr. 30.800 </strong> */
}
//         </div>
//       </div>
//       <div>
//         <h3>Gisting fyrir 2 gesti í eina eða tvær nætur</h3>
//         <div style={{ marginBottom: '1.2rem' }}>
//           Gisting fyrir 2 gesti í 2ja manna herbergi í eina nótt með
//           morgunverðarhlaðborði og 3ja rétta kvöldverði. Tilboðsverð{' '}
//           <strong>kr. 28.900 </strong>.
//         </div>
//       </div>
//       {/* <div>
//         <h3>Gisting fyrir 2 gesti í tvær nætur</h3>
//         <div style={{ marginBottom: '1.2rem' }}>
//           Gisting fyrir 2 gesti í 2ja manna herbergi í tvær nætur með
//           morgunverðarhlaðborði báða dagana og 3ja rétta kvöldverði
//           annað kvöldið. Tilboðsverð <strong>kr. 36.800 </strong>
//         </div>
//       </div> */}
//       <hr style={{ margin: '2rem 0' }} />
//       <div style={{ fontSize: '2rem' }}>
//         <strong>
//           Sparidagstilboðin er einungis hægt að panta í síma
//           gestamóttökunnar{' '}
//           <a href='tel:+3544868979' style={{ color: '#ffffff' }}>
//             486-8979
//           </a>{' '}
//           eða með því að senda fyrirspurn í{' '}
//           <a
//             href='mailto:info@hotelgullfoss.is'
//             style={{ color: '#ffffff' }}
//           >
//             tölvupósti
//           </a>
//           .
//         </strong>
//       </div>
//     </div>
//   </div>
// )}
// </Popup> */}
