import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import RoomsThree from '../img/HotelGullfoss/rooms-3.jpg';

import sprite from '../img/sprite.svg';

const StyledHeader = styled.h2`
  font-size: 16px;
  margin-bottom: 1em;
  color: #333;
`;

const StyledOffer = styled.div`
  position: relative;
  z-index: 5;

  &:before {
    position: absolute;
    top: 5px;
    left: -20px;
    width: 8px;
    height: 8px;
    border-radius: 35%;
    display: block;
    content: '';
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -1;
  }
`;

const StyledFooter = styled.div`
  font-size: 1.8rem;
`;

const StyledSvg = styled.svg`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  color: white;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const ModalOffer = ({ setDisplayModal, redirectLang }) => {
  const CancelCricle = sprite + '#icon-cancel-circle';

  const handleKeyPress = (e) => {
    console.log(e);
    if (e.code === 'Enter' || e.code === 'Space') {
      setDisplayModal(false);
    }
  };

  return (
    <div className='modal'>
      <div className='modal__header'>
        <span className='modal__header-title'>Sparidagstilboð Sumarsins</span>
        <a
          href={`https://property.godo.is/booking2.php?propid=26541&lang=${redirectLang}`}
          className='header__btn header__btn--white header__btn--animated modal__header-btn'
        >
          <FormattedMessage id='bookNow' />
        </a>
      </div>
      <div className='modal__content'>
        <div
          style={{
            float: 'right',
            position: 'inline-block',
          }}
        >
          <img
            src={RoomsThree}
            alt='Room offer'
            height='200px'
            className='modal__content-image'
          />
        </div>
        <StyledHeader>
          Nú fylgja 3ja rétta kvöldverður og morgunverðarhlaðborð með öllum
          herbergjum í sumar.
        </StyledHeader>
        <StyledSvg
          onClick={() => setDisplayModal(false)}
          onKeyPress={(e) => handleKeyPress(e)}
          role='button'
          tabIndex='0'
        >
          <use href={CancelCricle} />
        </StyledSvg>
        <StyledOffer>
          <h3>Gisting í Standard-herbergi, eina nótt fyrir 2 gesti</h3>
          <p style={{ marginBottom: '1.2rem' }}>
            Gisting í eina nótt í tveggja manna Standard-herbergi fyrir 2 gesti,
            með 3ja rétta kvöldverði og morgunverðarhlaðborði. Tilboðsverð á
            mann <strong>kr. 17.450</strong> eða <strong>kr. 34.900</strong>{' '}
            fyrir 2 gesti. Tilboðsverð fyrir einn gest,{' '}
            <strong>kr. 29.900</strong>
          </p>
        </StyledOffer>
        <StyledOffer>
          <h3>Gisting í Superior-herbergi, eina nótt fyrir 2 gesti</h3>
          <p style={{ marginBottom: '1.2rem' }}>
            Gisting í eina nótt í tveggja manna Superior-herbergi fyrir 2 gesti,
            með 3ja rétta kvöldverði og morgunverðarhlaðborði. Tilboðsverð á
            mann <strong>kr. 23.450</strong> eða <strong>kr. 46.900</strong>{' '}
            fyrir tvo gesti. Tilboðsverð fyrir einn gest,{' '}
            <strong>kr. 41.900</strong>
          </p>
        </StyledOffer>

        <hr style={{ margin: '2rem 0' }} />

        <StyledFooter>
          <strong>
            Tilboðin gilda frá 1. júní til 31. ágúst 2021. Þú getur pantað í
            gegnum bókunarkerfið okkar, síma gestamóttökunnar{' '}
            <a href='tel:+3544868979' style={{ color: '#ffffff' }}>
              486-8979
            </a>{' '}
            eða með því að senda fyrirspurn á{' '}
            <a href='mailto:info@hotelgullfoss.is' style={{ color: '#ffffff' }}>
              info@hotelgullfoss.is
            </a>
            .
          </strong>
        </StyledFooter>
      </div>
    </div>
  );
};

export default ModalOffer;
